@import '../../styles/constants';

$ICON_SIZE: 24px;
$V_PADDING: 9px;
$H_PADDING: 16px;
$LEFT_PADDING: $H_PADDING + $ICON_SIZE + 8px;

.main {
  position: relative;
  color: var(--Basic-N60);

  .icon {
    position: absolute;
    top: $V_PADDING;
    left: $H_PADDING;
    width: $ICON_SIZE;
    height: $ICON_SIZE;
    margin-top: 8px;
  }

  input {
    width: 100%;
    padding: $V_PADDING $H_PADDING $V_PADDING 48px;
    border: 3px solid var(--Basic-N10);
    border-radius: 20px;
    background: var(--Basic-N10);
    line-height: 32px;
    outline: none;

    ::placeholder {
      color: var(--Basic-N60);
    }

    &:focus {
      border-color: var(--Secondary-Violet-V90);
    }

    &:disabled {
      border-color: var(--Basic-N10);
      background-color: var(--Basic-N10);
      opacity: 0.7;
    }
  }

  &.invalid {
    input {
      border-color:  var(--Secondary-Red-R80);
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      box-shadow: 0 0 0 1000px var(--Neutral-N10) inset !important;
      -webkit-text-fill-color: var(--Neutral-N900) !important;
      transition: background-color 5000s ease-in-out 0s;
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .main {
    .error {
      position: absolute;
      bottom: -28px;
      left: 0;
      margin-top: 0;
    }
  }
}
