.main {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  will-change: opacity, transform;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
}
