@import '../../styles/constants';

footer.main {
  width: 100%;
  padding-top: 102px;
  padding-bottom: 102px;
  background-color: var(--Basic-N30);
  gap: 44px;

  section {
    padding-top: 0;
    padding-bottom: 0;
    gap: 44px;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      height: 36px;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    gap: 24px;

    li {
      margin-top: 0;
      list-style: none;
    }

    a {
      color: var(--Basic-N80);
    }
  }
}

@media (min-width: $TABLET_BREAKPOINT) {
  footer.main {
    nav {
      flex-direction: row;
    }
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  footer.main {
    padding-top: 142px;
    padding-bottom: 142px;

    section {
      gap: 24px;
    }

    .top {
      .logo {
        height: 40px;
      }
    }
  }
}
