$DESKTOP_BREAKPOINT: 1280px;
$LAPTOP_BREAKPOINT: 1024px;
$TABLET_BREAKPOINT: 768px;
$MOBILE_BREAKPOINT: 575px;

$HEADER_HEIGHT: 92px;
$CONTAINER_PADDING_TOP: 88px;

$Z_INDEX_HEADER: 999;

$MOBILE_H2_LINE_HEIGHT: 54px;
