:root {
  // Basic
  --Basic-N10: #FFF;
  --Basic-N30: #F1F0EC;
  --Basic-N40: #D5D5D5;
  --Basic-N60: #A6A6A6;
  --Basic-N80: #727272;
  --Basic-N100: #222;

  // Secondary-Pink
  --Secondary-Pink-P30: #FFC2E1;
  --Secondary-Pink-P80: #FF9ACD;
  --Secondary-Pink-P100: #470526;

  // Secondary-Violet
  --Secondary-Violet-V30: #D9D6FB;
  --Secondary-Violet-V80: #C0BAF8;
  --Secondary-Violet-V90: #7D75C7;
  --Secondary-Violet-V100: #0E0564;

  // Secondary-Green
  --Secondary-Green-G80: #C5F07C;
  --Secondary-Green-G100: #293D07;

  // Secondary-Yellow
  --Secondary-Yellow-Y30: #FFF4CE;
  --Secondary-Yellow-Y80: #FFEDAD;

  // Secondary-Red
  --Secondary-Red-R80: #F0604D;
  --Secondary-Red-R100: #983B2F;
}
