.close {
  width: 24px;
  height: 24px;
  align-self: center;
}

.main {
  gap: 4px;

  .title {
    font-weight: 600;
    line-height: 24px;
  }
}
