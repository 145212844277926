@import '../../../../styles/constants';

.main {
  display: flex;
  max-width: 350px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;

  img {
    width: 64px;
    height: 44px;
  }
}

@media (min-width: $TABLET_BREAKPOINT) {
  .main {
    max-width: 545px;
  }
}
