@import '~react-toastify/dist/ReactToastify.css';

/* stylelint-disable font-family-no-missing-generic-family-keyword, selector-class-pattern */
.Toastify {
  &__toast-body {
    padding: 0;
  }

  &__toast {
    width: 400px;
    padding: 20px 16px;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &--default:hover {
      // background-color: $grey_focused-stroke-scroll-bar;
    }

    &--error {
      background-color: var(--Red-R200);
      color: var(--Neutral-N0);
    }

    &--success {
      background-color: var(--Neutral-N40);
      color: var(--Neutral-N500);

      .Toastify__toast-body {
        align-items: flex-start;
      }

      .Toastify__toast-close {
        align-self: flex-start;
      }
    }
  }

  &__toast-icon {
    width: 24px;
  }

  &__progress-bar {
    &--error {
      background-color: var(--Red-R400);
    }

    &--success {
      background-color: var(--Neutral-N80);
    }
  }
}
