@import '../../../../styles/constants';

$CARDS_MARGIN_TOP: 20px;
$CARD_EXTRA_TOP: 32px;

section.main {
  position: relative;
  padding: 102px 0 0;

  .heading {
    text-align: center;
  }

  .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $CARDS_MARGIN_TOP + $CARD_EXTRA_TOP;
    gap: 24px;
  }

  .card {
    position: sticky;
    max-width: 450px;
    padding-top: 32px;
    border-radius: 36px;
    gap: 16px;

    .title,
    .description {
      padding: 0 24px;
    }

    img {
      width: 100%;
      align-self: center;
      border-bottom-left-radius: 36px;
      border-bottom-right-radius: 36px;
    }

    &.security {
      top: $HEADER_HEIGHT;
      background-color: var(--Secondary-Pink-P80);
      color: var(--Secondary-Pink-P100);
    }

    &.fair {
      top: $HEADER_HEIGHT + $CARD_EXTRA_TOP;
      background-color: var(--Secondary-Green-G80);
      color: var(--Secondary-Green-G100);
    }

    &.ease {
      top: $HEADER_HEIGHT + (2 * $CARD_EXTRA_TOP);
      background-color: var(--Secondary-Violet-V80);
      color: var(--Secondary-Violet-V100);
    }
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  section.main {
    .cards {
      padding-right: 36px;
      padding-left: 36px;
    }

    .wrapper {;
      display: grid;
      height: 668px;
      gap: 24px;
      grid-template-columns: 5fr 7fr;

      .right {
        gap: 24px;
      }

      .card {
        position: relative;
        top: initial;
        max-width: initial;
        flex-grow: 1;
        padding: 20px;

        .title,
        .description {
          padding: 0;
        }

        .image {
          position: absolute;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100%;
        }

        &.security {
          justify-content: space-between;

          .image {
            width: 210px;
            height: 300px;
            transition: transform 0.5s ease;
            will-change: transform;
          }

          .one {
            bottom: 290px;
            left: 0;
            background-image: url('../../../../../public/assets/priorities/desktop/one.png');
            transform: rotate(-17deg);
          }

          .two {
            bottom: 250px;
            left: 25%;
            background-image: url('../../../../../public/assets/priorities/desktop/two.png');
            transform: rotate(-6deg);
          }

          .three {
            right: -10px;
            bottom: 140px;
            background-image: url('../../../../../public/assets/priorities/desktop/three.png');
            transform: rotate(5deg);
          }

          &:hover {
            .one {
              transform: rotate(-26deg);
            }

            .two {
              transform: rotate(6deg);
            }

            .three {
              transform: rotate(20deg);
            }
          }
        }

        &.ease,
        &.fair {
          overflow: hidden;
        }

        &.ease {
          justify-content: flex-end;

          .description {
            width: 355px;
          }

          .image {
            box-shadow: 0 3px 25px 0 rgb(14 5 100 / 28%);
          }

          .buttons {
            top: 30px;
            left: 60px;
            display: flex;
            width: 245px;
            height: 85px;
            align-items: center;
            justify-content: center;
            border-radius: 24px;
            background: var(--Basic-N10);
            gap: 14px;
            transform: rotate(-9deg);
          }

          .dislike,
          .like,
          .star {
            position: initial;
            border-radius: 50%;
          }

          .dislike,
          .star {
            box-shadow: 0 13px 33px 0 rgb(0 0 0 / 7%);
          }

          .dislike {
            width: 52px;
            height: 52px;
            background-image: url('../../../../../public/assets/priorities/desktop/dislike.png');
          }

          .like {
            width: 65px;
            height: 65px;
            background-image: url('../../../../../public/assets/priorities/desktop/like.png');
            transition: width 0.5s ease, height 0.5s ease;
            will-change: width, height;
          }

          .star {
            width: 52px;
            height: 52px;
            background-image: url('../../../../../public/assets/priorities/desktop/star.png');
          }

          .wrapper {
            right: 0;
            bottom: -20px;
            width: 170px;
            height: 288px;
            border-radius: 18px;
            background-image: url('../../../../../public/assets/priorities/desktop/wrapper.png');
            background-position: 0 0;
          }

          .messages {
            right: 15px;
            bottom: 0;
            width: 134px;
            height: 107px;
            background-image: url('../../../../../public/assets/priorities/desktop/messages.png');
            background-position: 0 0;
            box-shadow: none;
            transition: background-position 0.5s ease;
            will-change: background-position;
          }

          &:hover {
            .like {
              width: 88px;
              height: 88px;
            }

            .messages {
              background-position: 0 -80px;
            }
          }
        }

        &.fair {
          .image {
            box-shadow: 0 4px 37px 0 rgb(41 61 7 / 30%);
          }

          .match {
            z-index: 3;
            bottom: 15px;
            left: 20px;
            width: 210px;
            height: 52px;
            border-radius: 999px;
            background: var(--Secondary-Violet-V30);
            transition: left 0.5s ease, bottom 0.5s ease;
            will-change: left, bottom;

            &::after {
              position: absolute;
              display: block;
              padding: 8px 20px;
              color: var(--Basic-N100);
              content: 'It’s a match!';
              font-size: 28px;
              font-style: normal;
              font-weight: 600;
              inset: 0;
              line-height: 130%;
              text-align: center;
            }
          }

          .left,
          .right {
            width: 160px;
            height: 240px;
            border-radius: 15px;
            transition: transform 0.5s ease, bottom 0.5s ease, background-size 0.5s ease;
            will-change: transform, bottom, background-size;
          }

          .left {
            z-index: 2;
            bottom: -90px;
            left: 210px;
            background-image: url('../../../../../public/assets/priorities/desktop/left.png');
            transform: rotate(-10deg);
          }

          .right {
            z-index: 1;
            bottom: -120px;
            left: 350px;
            background-image: url('../../../../../public/assets/priorities/desktop/right.png');
            transform: rotate(10deg);
          }

          .heart {
            z-index: 4;
            bottom: 90px;
            left: 180px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-image: url('../../../../../public/assets/priorities/desktop/heart.png');
            transform: rotate(-10deg);
            transition: transform 0.5s ease, bottom 0.5s ease, left 0.5s ease;
            will-change: transform, bottom, left;

            & + .heart {
              bottom: 80px;
              left: 350px;
              transform: rotate(10deg);
            }
          }

          &:hover {
            .match {
              bottom: 10px;
              left: 35px;
            }

            .left,
            .right {
              background-size: 110%;
            }

            .left {
              transform: rotate(-17deg);
            }

            .right {
              bottom: -110px;
              transform: rotate(15deg);
            }

            .heart {
              bottom: 90px;
              left: 145px;
              transform: rotate(-17deg);

              & + .heart {
                bottom: 115px;
                left: 350px;
                transform: rotate(15deg);
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $DESKTOP_BREAKPOINT) {
  section.main {
    .cards {
      padding-right: 80px;
      padding-left: 80px;

      .card {
        padding: 36px 60px 48px;

        &.security {
          .one {
            bottom: 250px;
            left: 10px;
          }

          .two {
            bottom: 230px;
            left: 130px;
          }

          .three {
            right: 0;
            bottom: 190px;
          }
        }

        &.ease {
          .buttons {
            left: 100px;
          }

          .wrapper {
            right: -20px;
            bottom: -20px;
            width: 227px;
            height: 320px;
          }

          .messages {
            right: 15px;
            bottom: -10px;
            width: 166px;
            height: 100px;
          }

          &:hover {
            .messages {
              background-position: 0 -100px;
            }
          }
        }

        &.fair {
          .match {
            left: 60px;
          }

          .left {
            left: 255px;
          }

          .right {
            left: 400px;
          }

          .heart {
            left: 220px;

            & + .heart {
              left: 390px;
            }
          }

          &:hover {
            .match {
              left: 75px;
            }

            .heart {
              bottom: 90px;
              left: 195px;

              & + .heart {
                bottom: 115px;
                left: 405px;
              }
            }
          }
        }
      }
    }
  }
}
