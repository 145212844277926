@import '../../styles/constants';

.main {
  position: fixed;
  z-index: $Z_INDEX_HEADER;
  top: 0;
  display: flex;
  width: 100vw;
  height: $HEADER_HEIGHT;
  align-items: center;
  animation: show-header 1s ease-in-out forwards;
  backdrop-filter: blur(4px);
  background-color: rgb(241 240 236 / 90%);
  transform: translateY(-$HEADER_HEIGHT);
  will-change: transform;

  .inner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 0;

    .logo {
      height: 52px;
    }
  }
}

@keyframes show-header {
  to {
    transform: translateY(0);
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .main {
    .logo {
      height: 40px;
    }
  }
}
