@import '../../../../styles/constants';

.main {
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 100vh;
  margin-top: 60px;
  background-color: var(--Secondary-Violet-V30);

  .inner {
    gap: 52px;
  }

  .heading {
    text-align: center;
  }

  .form {
    display: flex;
    width: 100%;
    max-width: $MOBILE_BREAKPOINT;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    justify-content: center;
    padding: 20px;
    border-radius: 40px;
    animation: swing 2s ease-in-out infinite;
    background: var(--Basic-N10);
    gap: 16px;

    .loader span {
      animation-duration: 4s;
      animation-iteration-count: infinite;
    }

    a {
      width: 100%;
    }
  }
}

@keyframes swing {
  0% {
    transform: rotate(-1deg);
  }

  50% {
    transform: rotate(-4deg);
  }

  100% {
    transform: rotate(-1deg);
  }
}

@media (min-width: $TABLET_BREAKPOINT) {
  .main {
    .form {
      max-width: 650px;
      flex-direction: row;

      a {
        width: initial;
        flex-shrink: 1;
      }

      .loader {
        width: 50%;
        flex-grow: 1;
      }
    }
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .main {
    position: relative;
    top: initial;
    min-height: initial;
  }
}

@media (min-width: $DESKTOP_BREAKPOINT) {
  .main {
    background-color: initial;
  }
}
