.main {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 64px;
  border-radius: 20px;

  .progress {
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
      -65deg,
      var(--Secondary-Yellow-Y80),
      var(--Secondary-Yellow-Y80) 20px,
      #9E97DF 20px,
      #9E97DF 40px
    );
  }

  .overlay {
    position: absolute;
    animation: load 1s ease-in-out 1;
    animation-fill-mode: forwards;
    background: var(--Basic-N30);
    inset: 0;
    will-change: left;
  }
}

@keyframes load {
  0% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}
