.main {
  padding: 4px;
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;

  &:hover {
    border-radius: 50%;
  }
}

.disabled {
  cursor: default;
}