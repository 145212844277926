@import '../../../../styles/constants';

.main {
  align-items: center;
  background-color: var(--Secondary-Pink-P30);
  gap: 48px;

  .heading,
  .description {
    text-align: center;
  }

  .description {
    max-width: 738px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .card {
    width: 100%;
    max-width: $MOBILE_BREAKPOINT;
    align-items: center;
    padding: 28px 20px 20px;
    border-radius: 36px;
    background: var(--Basic-N10);
    gap: 16px;

    img {
      height: 85px;
    }

    p {
      color: var(--Basic-N80);
      text-align: center;
    }

    &:hover img {
      animation: shake 0.6s linear forwards;
    }

    &.shake img {
      animation: shake-back 0.6s linear forwards;
    }
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .main {
    .cards {
      flex-direction: row;
      align-items: stretch;
    }

    .card {
      max-width: 358px;
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}

@media (min-width: $DESKTOP_BREAKPOINT) {
  .main {
    background-color: initial;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0);
  }

  40% {
    transform: rotate(40deg);
  }

  55% {
    transform: rotate(30deg);
  }

  70% {
    transform: rotate(38deg);
  }

  85% {
    transform: rotate(35deg);
  }

  100% {
    transform: rotate(40deg);
  }
}

@keyframes shake-back {
  0% {
    transform: rotate(40deg);
  }

  40% {
    transform: rotate(0);
  }

  55% {
    transform: rotate(10deg);
  }

  70% {
    transform: rotate(2deg);
  }

  85% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
