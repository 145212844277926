@import '../../../../styles/constants';

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 48px;

  .swiper {
    width: 100%;
  }

  .generator {
    display: none;
  }

  .content {
    align-items: center;
    padding-bottom: 0;
    gap: 32px;
  }

  .title,
  .subtitle {
    display: flex;
    flex-direction: column;

    span {
      text-align: center;
    }
  }

  .subtitle {
    color: var(--Basic-N80);
    text-align: center;
  }

  a {
    display: inline-flex;
    width: 100%;
    max-width: $MOBILE_BREAKPOINT;
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .main {
    flex-direction: column-reverse;

    .swiper {
      display: flex;
      justify-content: center;
    }

    .generator {
      position: relative;
      display: block;
      width: 100%;
      max-width: 1120px;
      height: calc(100vw * 9 / 16);
      max-height: 630px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        opacity: 0;
        transition: opacity 1s ease;
        will-change: opacity;

        &.visible {
          opacity: 1;
        }

        &.right {
          right: 0;
          left: initial;
        }
      }
    }

    .content {
      padding-top: 0;
    }

    a {
      width: initial;
    }
  }
}
