@import './toast';
@import './colors';
@import './swiper';
@import './animation';
@import './constants';
@import './typography';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: var(--Basic-N30);
  color: var(--Basic-N100);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
header {
  transition: background-color 0.3s ease;
  will-change: background-color;
}

p, ul, ol, dl, h1, h2, h3, h4, h5, h6, blockquote, pre, form, table {
  padding: 0;
  margin: 0;
}

a, button {
  text-decoration: none !important;

  &:focus-visible {
    outline: var(--Blue-B100) solid 1px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

fieldset {
  padding: 0;
  border: none;
  margin: 0;
}

#root {
  height: 100%;
}

.layout {
  max-width: $MOBILE_BREAKPOINT;
  padding: 0;
  margin: 0 auto;
}

.container {
  padding: $CONTAINER_PADDING_TOP 16px 60px;
}

.container-h-margin {
  margin-right: 16px;
  margin-left: 16px;
}

.d-flex {
  display: flex;
  flex-direction: column;
}

.d-flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.svg-fill-current-color {
  svg {
    path {
      /* stylelint-disable-next-line value-keyword-case */
      fill: currentColor;
    }
  }
}

.svg-stroke-current-color {
  svg {
    path {
      /* stylelint-disable-next-line value-keyword-case */
      stroke: currentColor;
    }
  }
}

@media (min-width: $TABLET_BREAKPOINT) {
  .layout {
    max-width: $DESKTOP_BREAKPOINT;
  }

  .container {
    padding-right: 36px;
    padding-left: 36px;
  }

  .container-h-margin {
    margin-right: 36px;
    margin-left: 36px;
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .container {
    padding: 92px 36px 88px;
  }
}

@media (min-width: $DESKTOP_BREAKPOINT) {
  .container {
    padding-right: 80px;
    padding-left: 80px;
  }

  .container-h-margin {
    margin-right: 80px;
    margin-left: 80px;
  }
}
