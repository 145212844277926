@import '../../../../styles/constants';

section.main {
  padding-top: 102px;

  .heading {
    text-align: center;
  }

  .collage {
    display: none;
  }

  .cards {
    display: flex;
    flex-direction: column;
    margin-top: 52px;
    gap: 20px;
  }

  .item {
    height: 278px;
    align-items: center;
    padding: 28px 20px 20px;
    border-radius: 36px;
    gap: 12px;

    img {
      height: 85px;
    }

    &.glass {
      background-color: var(--Secondary-Violet-V80);
      color: var(--Secondary-Violet-V100);
    }

    &.diamond {
      background-color: var(--Secondary-Pink-P80);
      color: var(--Secondary-Pink-P100);
    }

    &.hand {
      background-color: var(--Secondary-Green-G80);
      color: var(--Secondary-Green-G100);
    }
  }

  .title,
  .caption {
    text-align: center;
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  section.main {
    position: relative;
    height: 500vh;

    .wrapper {
      position: sticky;
      top: 0;
      left: 0;
      height: 100vh;
    }

    .collage {
      position: absolute;
      display: block;
      inset: 0;

      .start {
        position: sticky;
        z-index: 1;
        top: 50%;
        margin: 92px 0;
        will-change: transform;

        .top {
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          width: 265px;
          height: 74px;
          transform: translate(-50%, -117%);
          transform-origin: 95% 70%;
          will-change: transform;
        }

        .bottom {
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          width: 350px;
          height: 228px;
          transform: translate(-50%, -50%);
        }
      }

      .photos {
        position: absolute;
        inset: 0;

        .scale {
          position: absolute;
          z-index: 2;
          inset: 0;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          opacity: 1;

          // transform-origin: center;
          transform-origin: 0% 0%;
          transition: opacity 0s, transform 0s;
          will-change: opacity, transform;
        }

        .stone {
          z-index: 3;
          width: 220px;
        }

        .tower {
          z-index: 4;
          width: 280px;
        }

        .bikini {
          z-index: 5;
          width: 250px;
        }

        .left {
          z-index: 6;
          width: 230px;
        }

        .flowers {
          z-index: 7;
          width: 320px;
        }

        .pizza {
          z-index: 8;
          width: 300px;
        }

        .center {
          z-index: 9;
          width: 300px;
        }

        .milkshake {
          z-index: 10;
          width: 150px;
        }

        .candy {
          z-index: 11;
          width: 220px;
        }

        .lipstick {
          z-index: 12;
          width: 220px;
        }

        .chips {
          z-index: 13;
          width: 250px;
        }

        .right {
          z-index: 14;
          width: 300px;
        }

        .cocktail {
          z-index: 15;
          width: 200px;
        }

        .lips {
          z-index: 16;
          width: 250px;
        }

        .ice {
          z-index: 17;
          width: 260px;
        }

        .wine {
          z-index: 18;
          width: 180px;
        }
      }

      &.trigger {
        .photos {
          .scale {
            transform: scale(1);

            .stone {
              transform: translate(-500px, -500px);
            }

            .tower {
              transform: translate(-500px, -500px);
            }

            .bikini {
              transform: translate(-500px, 300px);
            }

            .left {
              transform: translateX(-500px);
            }

            .flowers {
              transform: translate(-500px, -100px);
            }

            .pizza {
              transform: translate(-500px, 300px);
            }

            .center {
              transform: translate(2000px, -500px);
            }

            .lipstick {
              transform: translate(500px, -500px);
            }

            .chips {
              transform: translate(1000px, -500px);
            }

            .right {
              transform: translate(500px, 200px);
            }

            .cocktail {
              transform: translate(500px, -100px);
            }

            .lips {
              transform: translate(1000px, 100px);
            }

            .ice {
              transform: translate(2000px, 500px);
            }
          }
        }
      }
    }

    .container {
      position: sticky;
      top: 92px;
    }

    .cards {
      flex-direction: row;
      transition: opacity 0.5s ease;
    }

    .item {
      max-width: 358px;
      height: initial;
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}
