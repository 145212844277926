@import '../../styles/constants';

.main {
  height: 100%;
  min-height: 100vh;
  margin-top: $HEADER_HEIGHT;

  .layout {
    animation: fade-in 0.2s ease-in-out forwards;
    animation-delay: 0.1s;
    opacity: 0;
    will-change: opacity;
  }
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}
