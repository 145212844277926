@import '../../../../styles/constants';

section.main {
  padding-bottom: 42px;
  background-color: var(--Secondary-Yellow-Y30);

  .heading {
    text-align: center;
  }

  .swiper {
    margin-top: 52px;
  }

  .card {
    display: flex;
    width: 262px;
    min-height: 316px;
    flex-direction: column;
    flex-shrink: 0;
    align-self: stretch;
    padding: 16px 20px 24px;
    border-radius: 36px;
    background: var(--Basic-N10);

    .index {
      width: 74px;
      height: 74px;
      border-radius: 999px;
      color: var(--Basic-N100);
      font-size: 54px;
      line-height: 54px;
    }

    &:nth-child(3),
    &:nth-child(4) {
      .index {
        padding-bottom: 12px;
      }
    }

    .title {
      margin-top: 16px;
      margin-bottom: 12px;
    }

    .description {
      color: var(--Basic-N80);
    }

    &::after {
      position: absolute;
      right: -60px;
      bottom: -110px;
      display: block;
      width: 90px;
      height: 90px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
    }

    &.create {
      .index {
        background: var(--Secondary-Yellow-Y80);
      }

      &::after {
        background-image: url('./assets/create.png');
      }
    }

    &.choose {
      .index {
        background: var(--Secondary-Green-G80);
      }

      &::after {
        background-image: url('./assets/choose.png');
      }
    }

    &.accept {
      .index {
        background: var(--Secondary-Violet-V80);
      }

      &::after {
        background-image: url('./assets/accept.png');
      }
    }

    &.enjoy {
      .index {
        background: var(--Secondary-Pink-P80);
      }
    }
  }
}

@media (min-width: $DESKTOP_BREAKPOINT) {
  section.main {
    background-color: initial;
  }
}
