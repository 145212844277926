@import '../../../../styles/constants';

section.main {
  padding-bottom: 88px;
  background-color: var(--Secondary-Yellow-Y30);

  .inner {
    position: relative;
    border-radius: 36px;
    background-color: var(--Secondary-Green-G80);
    transition: background-color 0.3s ease;
    will-change: background-color;

    .content,
    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      justify-content: flex-start;
      gap: 24px;
      transition: opacity 0.3s ease;
      will-change: opacity;
    }

    .content {
      padding: 44px 24px;
    }

    .submitted {
      position: absolute;
      inset: 0;
      opacity: 0;
    }

    .initial {
      form {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 16px;
      }

      img {
        height: 68px;
      }
    }

    &.submitting,
    &.success {
      .initial {
        opacity: 0;
      }

      .submitted {
        opacity: 1;
      }

      .cat {
        height: 134px;
      }
    }

    &.submitting {
      background-color: transparent;

      .info {
        opacity: 0;
      }
    }

    &.success {
      background-color: var(--Secondary-Violet-V30);
    }

    .loader {
      width: 100%;
      padding: 12px;
      border-radius: 32px;
      margin-top: auto;
      animation: swing 1s ease-in-out 1 forwards;
      background-color: var(--Basic-N10);
      transform: rotate(0);
    }
  }
}

@keyframes swing {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-4deg);
  }

  100% {
    transform: rotate(0);
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  section.main {
    display: flex;
    justify-content: center;

    .inner {
      max-width: 930px;

      .content {
        padding: 48px 80px;

        img {
          height: 134px;
        }
      }

      .initial {
        form {
          flex-direction: row;

          label {
            flex-grow: 1;
          }
        }
      }

      .loader {
        padding: 20px;
      }
    }
  }
}

@media (min-width: $DESKTOP_BREAKPOINT) {
  section.main {
    background-color: initial;
  }
}
