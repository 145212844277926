@import './constants';

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  src: url('../../public/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  src: url('../../public/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url('../../public/fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: url('../../public/fonts/OpenSans/OpenSans-Medium.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url('../../public/fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url('../../public/fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: "Yong Serif";
  font-style: normal;
  font-weight: normal;
  src: url('../../public/fonts/YoungSerif/YoungSerif-Regular.ttf') format('truetype');
}

body, p, div, a, button, article, main, header, footer, section, form, input, textarea, select {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  text-align: initial;
}

.young-serif, h1, h2, h3, h4, h5, .heading, .desktop-subheading {
  font-family: "Yong Serif", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  text-align: initial;
}

.heading {
  font-style: normal;
  font-weight: 400;
}

.mobile-h1 {
  font-size: 44px;
  letter-spacing: -0.44px;
  line-height: 52px;
}

.mobile-h2 {
  font-size: 44px;
  line-height: $MOBILE_H2_LINE_HEIGHT;
}

.mobile-subheading1 {
  font-size: 28px;
  line-height: 36px;
}

.mobile-subheading2 {
  font-size: 24px;
  line-height: 34px;
}

.text1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
}

.text2 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.text3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.caption {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.heading-h200 {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.ui-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .ui-text {
    font-size: 14px;
  }
}

@media (min-width: $DESKTOP_BREAKPOINT) {
  .heading {
    &.desktop-h1 {
      font-size: 72px;
      line-height: 72px;
    }

    &.desktop-h2 {
      font-size: 54px;
      line-height: 56px;
    }

    &.desktop-subheading1 {
      font-size: 32px;
      line-height: 36px;
    }

    &.desktop-subheading2 {
      font-size: 25px;
      line-height: 36px;
    }
  }
}
