.svg-animated {
  position: absolute;
  top: 0;
  right: 0;
}

.card-animated {
  background-color: var(--Neutral-N20);
  transition: background-color 1s ease;
  will-change: background-color;
}

.easy-animation {
  #easy-1,
  #easy-2,
  #easy-3,
  #easy-4,
  #easy-5,
  #easy-6,
  #easy-7,
  #easy-8,
  #easy-9 {
    transform: translateY(0);
    transition: transform 1s ease, fill 1s ease;
    will-change: transform, fill;
  }

  #easy-9 {
    transform: translateY(59px);
  }

  &:hover {
    background-color: var(--Secondary-Emerald-E300);

    #easy-1 {
      fill: var(--Secondary-Emerald-E25);
    }

    #easy-2 {
      fill: var(--Secondary-Emerald-E50);
      transform: translateY(-3px);
    }

    #easy-3 {
      fill: var(--Secondary-Emerald-E100);
      transform: translateY(-6px);
    }

    #easy-4 {
      fill: var(--Secondary-Emerald-E200);
      transform: translateY(-12px);
    }

    #easy-5 {
      fill: var(--Secondary-Emerald-E400);
      transform: translateY(-19px);
    }

    #easy-6 {
      fill: var(--Secondary-Emerald-E500);
      transform: translateY(-28px);
    }

    #easy-7 {
      fill: var(--Secondary-Emerald-E600);
      transform: translateY(-39px);
    }

    #easy-8 {
      fill: var(--Secondary-Emerald-E700);
      transform: translateY(-50px);
    }

    #easy-9 {
      fill: var(--Secondary-Emerald-E700);
      transform: translateY(0);
    }
  }
}

.database-animation {
  #database-4-wrapper,
  #database-5-wrapper,
  #database-6-wrapper,
  #database-7-wrapper,
  #database-4,
  #database-5,
  #database-6,
  #database-7 {
    transform-box: fill-box;
    transform-origin: center;
    will-change: transform, fill;
  }

  #database-1,
  #database-3 {
    transition: fill 1s ease;
    will-change: fill;
  }

  #database-4-wrapper {
    transition: transform 0.3s ease 0.6s;
  }

  #database-5-wrapper {
    transition: transform 0.4s ease 0.4s;
  }

  #database-6-wrapper {
    transition: transform 0.3s ease 0.2s;
  }

  #database-7-wrapper {
    transition: transform 0.4s ease 0.1s;
  }

  #database-4,
  #database-5,
  #database-6,
  #database-7 {
    transition: transform 0.3s ease, fill 1s ease;
  }

  #database-4 {
    transform: translate(146px, 12px);
  }

  #database-5 {
    transform: translate(85px, 24px);
  }

  #database-6 {
    transform: translate(36px, 36px);
  }

  #database-7 {
    transform: translate(0, 48px);
  }

  &:hover {
    background-color: var(--Secondary-Pink-Sakura-S300);

    #database-1,
    #database-3 {
      fill: var(--Secondary-Pink-Sakura-S75);
    }

    #database-4-wrapper {
      transform: translateX(7px);
    }

    #database-5-wrapper {
      transform: translateX(15px);
    }

    #database-6-wrapper {
      transform: translateX(25px);
    }

    #database-7-wrapper {
      transform: translateX(39px);
    }

    #database-4 {
      fill: var(--Secondary-Pink-Sakura-S200);
      transform: translate(146px, 0);
    }

    #database-5 {
      fill: var(--Secondary-Pink-Sakura-S400);
      transform: translate(85px, 0);
    }

    #database-6 {
      fill: var(--Secondary-Pink-Sakura-S500);
      transform: translate(36px, 0);
    }

    #database-7 {
      fill: var(--Secondary-Pink-Sakura-S700);
      transform: translate(0, 0);
    }
  }
}

.ai-animation {
  #ai-1 {
    transform: rotate(0);
  }

  #ai-2 {
    transform: translate(0, 0) rotate(0);
  }

  #ai-1,
  #ai-2 {
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 1s ease, fill 1s ease;
    will-change: transform, fill;
  }

  &:hover {
    background-color: var(--Secondary-Orange-O300);

    #ai-1 {
      fill: var(--Secondary-Orange-O75);
      transform: rotate(180deg);
    }

    #ai-2 {
      fill: var(--Secondary-Orange-O600);
      transform: translate(63.5px, -63.5px) rotate(180deg);
    }
  }
}

.tailored-animation {
  #tailored-1 {
    transform: rotate(0);
  }

  #tailored-1,
  #tailored-2,
  #tailored-3,
  #tailored-4 {
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 1s ease, fill 1s ease;
    will-change: transform, fill;
  }

  &:hover {
    background-color: var(--Blue-B500);

    #tailored-1 {
      fill: var(--Purple-P50);
      transform: rotate(45deg);
    }

    #tailored-2 {
      fill: var(--Blue-B75);
    }

    #tailored-3 {
      fill: var(--Blue-B200);
      transform: translateX(30px);
    }

    #tailored-4 {
      fill: var(--Blue-B400);
      transform: translateX(60px);
    }
  }
}

.security-animation {
  #security-3-wrapper {
    transform: translateY(-15px);
    transition: transform 0.2s ease 0.1s;
  }

  #security-4-wrapper {
    transform: translateY(-40px);
    transition: transform 0.2s ease 0.4s;
  }

  #security-5-wrapper {
    transform: translateY(-60px);
    transition: transform 0.2s ease 0.7s;
  }

  #security-6-wrapper {
    transform: translateY(80px);
    transition: transform 1s ease;
  }

  #security-3-wrapper,
  #security-4-wrapper,
  #security-5-wrapper,
  #security-6-wrapper {
    transform-origin: center;
    will-change: transform;
  }

  #security-1 {
    transform: translateY(0);
  }

  #security-2 {
    transform-box: fill-box;
    transform-origin: bottom;
    transition: transform 1s ease, fill 1s ease;
    will-change: transform, fill;
  }

  #security-1,
  #security-3,
  #security-4,
  #security-5,
  #security-6 {
    transform-box: fill-box;
    transform-origin: center;
    transition: fill 1s ease;
    will-change: fill;
  }

  &:hover {
    background-color: var(--Secondary-Pink-Sakura-S300);

    #security-3-wrapper {
      transform: translateY(0);
    }

    #security-4-wrapper {
      transform: translateY(0);
    }

    #security-5-wrapper {
      transform: translateY(0);
    }

    #security-6-wrapper {
      transform: translateY(0);
    }

    #security-1 {
      fill: #3F101B;
    }

    #security-2 {
      fill: #3F101B;
      transform: scaleY(3);
    }

    #security-3 {
      fill: #A72A49;
    }

    #security-4 {
      fill: #DA5D7C;
    }

    #security-5 {
      fill: #EDAEBD;
    }

    #security-6 {
      fill: #7D1F37;
    }
  }
}

.risk-animation {
  #risk-1 {
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 0.3s ease, fill 1s ease;
  }

  #risk-1,
  #risk-2,
  #risk-3 {
    will-change: transform, fill;
  }

  #risk-4 {
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 0.3s ease, fill 1s ease;
    will-change: transform, fill;
  }

  #risk-5 {
    transform-box: fill-box;
    transform-origin: center;
    transition: opacity 0.3s ease;
    will-change: opacity;
  }

  #risk-6 {
    opacity: 0;
    transform-box: fill-box;
    transform-origin: center;
    will-change: transform, opacity;
  }

  #risk-7 {
    opacity: 0;
    transform-box: fill-box;
    transform-origin: center;
    will-change: opacity;
  }

  #risk-8 {
    transform: translate(75px, 75px);
    will-change: transform;
  }

  &:hover {
    background-color: var(--Blue-B500);

    #risk-1 {
      fill: #EAE6FF;
      transform: translate(30px, 30px);
    }

    #risk-2 {
      fill: #C0B6F2;
      transform: translate(20px, -50px);
      transition: transform 0.2s ease 0.7s, fill 1s ease;
    }

    #risk-3 {
      fill: #8777D9;
      transform: translate(-20px, 50px);
      transition: transform 0.2s ease 0.5s, fill 1s ease;
    }

    #risk-4 {
      fill: #5243AA;
      transform: scale(1.8) translate(-17px, -17px);
    }

    #risk-5 {
      opacity: 0;
    }

    #risk-6 {
      fill: #403294;
      opacity: 1;
      transform: scale(5) translate(-20px, -20px);
      transition: transform 0.5s ease 0.3s, opacity 0.05s ease 0.25s;
    }

    #risk-7 {
      opacity: 1;
      transition: opacity 0.3s ease 0.3s;
    }

    #risk-8 {
      transform: translate(0, 0);
      transition: transform 0.3s ease 0.3s;
    }
  }

  &:not(:hover) {
    #risk-2 {
      transition: transform 0.2s ease, fill 1s ease;
    }

    #risk-3 {
      transition: transform 0.2s ease, fill 1s ease;
    }

    #risk-6 {
      transition: transform 0.5s ease, opacity 0.05s ease;
    }

    #risk-7 {
      transition: opacity 0.3s ease;
    }

    #risk-8 {
      transition: transform 0.3s ease;
    }
  }
}

.efficiency-animation {
  #efficiency-1-wrapper,
  #efficiency-2-wrapper,
  #efficiency-3-wrapper {
    transform-box: fill-box;
    transform-origin: center;
    will-change: transform;
  }

  #efficiency-1-wrapper {
    transform: rotate(-20deg);
    transition: transform 0.3s ease 0.1s;
  }

  #efficiency-2-wrapper {
    transform: rotate(-49deg);
    transition: transform 0.3s ease 0.4s;
  }

  #efficiency-3-wrapper {
    transform: rotate(-20deg);
    transition: transform 0.3s ease 0.4s;
  }

  #efficiency-1,
  #efficiency-2,
  #efficiency-3 {
    transform-box: fill-box;
    transform-origin: center;
    transition: fill 1s ease;
    will-change: fill;
  }

  &:hover {
    background-color: var(--Secondary-Orange-O300);

    #efficiency-1-wrapper {
      transform: translate(53px, 0) rotate(0);
    }

    #efficiency-2-wrapper {
      transform: translate(28px, 5px) rotate(0);
    }

    #efficiency-3-wrapper {
      transform: translate(0, 2px) rotate(0);
    }

    #efficiency-1 {
      fill: #301B00;
    }

    #efficiency-2 {
      fill: #FBDBB3;
    }

    #efficiency-3 {
      fill: #603600;
    }
  }
}

.solution-animation {
  #solution-1-wrapper,
  #solution-2-wrapper,
  #solution-3-wrapper,
  #solution-4-wrapper {
    transform-box: fill-box;
    transform-origin: center;
    will-change: transform;
  }

  #solution-1,
  #solution-2,
  #solution-3,
  #solution-4 {
    transform-box: fill-box;
    transform-origin: center;
    will-change: transform, fill;
  }

  #solution-4 {
    transition: transform 0.5s ease, fill 1s ease;
  }

  &:hover {
    background-color: var(--Secondary-Emerald-E300);

    #solution-1-wrapper {
      transform: translate(-69px, 15px) rotate(-90deg);
      transition: transform 0.175s ease 0.35s;
    }

    #solution-2-wrapper {
      transform: translate(-60px, 18px) rotate(-90deg);
      transition: transform 0.2s ease 0.35s;
    }

    #solution-3-wrapper {
      transform: translate(-60px, 22px) rotate(-90deg);
      transition: transform 0.225s ease 0.35s;
    }

    #solution-4-wrapper {
      transform: translate(-60px, 28px) rotate(-90deg);
      transition: transform 0.25s ease 0.35s;
    }

    #solution-1 {
      fill: #032117;
      transform: translate(0, -52px) rotate(-180deg);
      transition: transform 0.3s ease 0.6s, fill 1s ease;
    }

    #solution-2 {
      fill: #06422E;
      transform: translate(42px, 0);
      transition: transform 0.3s ease 0.2s, fill 1s ease;
    }

    #solution-3 {
      fill: #3E9377;
      transform: translate(90px, 0);
      transition: transform 0.4s ease 0.125s, fill 1s ease;
    }

    #solution-4 {
      fill: #8FF4D2;
      transform: translate(140px, 0);
    }
  }

  &:not(:hover) {
    #solution-1-wrapper {
      transition: transform 0.175s ease;
    }

    #solution-2-wrapper {
      transition: transform 0.2s ease;
    }

    #solution-3-wrapper {
      transition: transform 0.225s ease;
    }

    #solution-4-wrapper {
      transition: transform 0.25s ease;
    }

    #solution-1 {
      transition: transform 0.2s ease, fill 1s ease;
    }

    #solution-2 {
      transition: transform 0.25s ease, fill 1s ease;
    }

    #solution-3 {
      transition: transform 0.25s ease, fill 1s ease;
    }
  }
}
