@import '../../../../styles/constants';

section.main {
  align-items: center;
  padding-top: 102px;
  padding-bottom: 0;
  gap: 52px;

  .title {
    text-align: center;
  }
}
