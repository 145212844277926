@import './constants';

$MAX_PHOTO_WIDTH: 560px;

.hero-swiper,
.how-swiper {
  max-width: $MAX_PHOTO_WIDTH;
}

.hero-swiper {
  width: 100vw;
}

.how-swiper {
  width: calc(100vw - 2 * 16px);
  padding-bottom: 110px;
}

@media (min-width: $TABLET_BREAKPOINT) {
  .how-swiper {
    max-width: calc(100vw - 2 * 36px);
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .hero-swiper {
    max-width: $MAX_PHOTO_WIDTH * 2;
  }
}

@media (min-width: $DESKTOP_BREAKPOINT) {
  .how-swiper {
    width: initial;

    .swiper-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}
