@import '../../styles/constants';

.main {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 20px;
  background: transparent;
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 20px;
  outline: none;
  text-align: center;
  text-decoration: none !important;

  &.ripple {
    overflow: hidden;

    &::after {
      position: absolute;
      bottom: -50%;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      content: '';
      pointer-events: none;
      transform: scale(0);
      will-change: transform, opacity, bottom;
    }

    &:hover::after {
      bottom: 0;
      opacity: 1;
      transform: scale(1.1, 3);
      transition: transform 0.5s, opacity 0.3s, bottom 1s;
    }
  }

  &.primary {
    padding: 16px 32px;
    background-color: var(--Secondary-Pink-P80);
    color: var(--Basic-N100);

    &:hover::after {
      background-color: var(--Secondary-Violet-V80);
    }

    &:active, &:focus {
      background-color: var(--Secondary-Pink-P80);
      box-shadow: inset 0 0 0 3px var(--Secondary-Violet-V90);
    }
  }

  &.secondary {
    background-color: var(--Basic-N100);
    color: var(--Basic-N30);

    &:hover::after {
      background-color: var(--Basic-N80);
    }

    &:active, &:focus {
      background-color: var(--Basic-N100);
      box-shadow: inset 0 0 0 3px var(--Secondary-Violet-V80);
    }
  }

  &.primary,
  &.secondary {
    &:disabled {
      &, &:hover, &:active, &:focus, &.active {
        background-color: var(--Basic-N40);
        color: var(--Basic-N80);
        cursor: default;
      }
    }

    &:hover {
      .label {
        opacity: 1;
      }
    }
  }

  &.tetriary {
    padding: 0;
    color: var(--Basic-N80);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 20px;

    &:hover {
      color: var(--Secondary-Violet-V90);
    }

    &:active, &:focus, &.active {
      color: var(--Basic-N80);
    }

    &:disabled {
      &, &:hover, &:active, &:focus, &.active {
        color: var(--Basic-N60);
        cursor: default;
      }
    }

    &.small,
    &.medium,
    &.large {
      padding: 0;
    }
  }

  &.small {
    padding: 12px 28px;
  }

  &.medium,
  &.large {
    padding: 16px 32px;
  }

  &.large {
    line-height: 32px;
  }

  &.wide {
    width: 100%;
  }

  .label {
    position: absolute;
    z-index: 9;
    inset: 0;
    opacity: 0;
  }
}

@media (min-width: $TABLET_BREAKPOINT) {
  .main {
    &.wide {
      width: 50%;
      margin: 0 auto;
    }
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .main {
    &.wide {
      width: initial;
      margin: initial;
    }
  }
}
