@import '../../styles/constants';

.main {
  margin-top: 152px;

  .title {
    text-align: center;
  }

  .button {
    width: 100%;
    margin-top: 32px;
    text-align: center;
  }
}

@media (min-width: $TABLET_BREAKPOINT) {
  .main {
    margin-top: 120px;
  }
}
